@import "themes/default";

.index-header {
    display: flex;
}

/*服务*/
.tag-select {
    margin-top: 20px;

    h3 {
        color: @gray-two-color;
        font-weight: normal;
    }
}

/*服务end*/

/*首页园区切换*/
.index-content {
    position: absolute;
    top: 76px;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: 1200px;
    overflow: hidden;

    .swiper-wrapper {
        .swiper-slide {
            height: calc(~"100vh - 76px") !important;

            &.auto-height {
                height: 264px !important;
            }
        }
    }
}

.park-select {
    width: 380px;
    overflow: auto;
    max-height: 500px;


    .ant-radio-wrapper {
        // width: 100%;
        width: calc(~"50% - 8px");
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.park-select::-webkit-scrollbar {
    //滚动条整体样式
    width: 5px;
    height: 5px;
}

.park-select::-webkit-scrollbar-thumb {
    //滚动条小方块样式
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #b9b9b9;
}

.park-select::-webkit-scrollbar-track {
    //滚动条滚动轴样式
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #eeeeee;
}

.park-select-title {
    height: 35px;
    line-height: 35px;

    i {
        margin-right: 5px;
    }
}

/*搜索列表*/
.page-header {
    margin-top: 32px;
    margin-bottom: 32px;
    position: relative;
    background-color: @white-color;

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    .ant-input-searchn {
        margin-left: 16px;
    }
}

/*搜索列表end*/

/*活动详情*/
.collect-badge {
    cursor: pointer;
    margin: 0 0 0 10px;
    display: inline-block;

    .icon {
        margin-right: 3px;
        font-size: 14px;
    }

    &:hover,
    &.active {
        color: @primary-color;
    }
}

.share-badge {
    cursor: pointer;
    margin: 0 0 0 16px;
    display: inline-block;

    .icon {
        margin-right: 3px;
        font-size: 14px;
    }

    &:hover,
    &.active {
        color: @primary-color;
    }
}

.check-map {
    height: 32px;
    width: 88px;
    display: inline-block;
    border-radius: 4px;
    color: @primary-color;
    font-size: 14px;
    border: 1px solid @primary-color;
    text-align: center;
    line-height: 29px;

    &:hover {
        background-color: @primary-color;
        color: #fff;
    }
}

.details-intro-header {
    .rows {
        width: 100%;

        em {
            display: inline-block;
            font-style: normal;
            margin-right: 12px;
            color: @text;
        }
    }
}

/*活动详情end*/

/*资讯详情*/
.right-hoving {
    position: fixed;
    max-width: 246px;
    z-index: 9;
    top: 155px;
}

.hovering-box {
    position: fixed;
    width: 1200px;
    left: 50%;
    transform: translate(-50%, 0);
    top: 68px;
    padding-top: 16px;
    text-align: center;
    background-color: #fff;
    z-index: 9;

    .hovering-name {
        background-color: #fff;
        border: solid 1px #eee;
        padding: 15px;

        &>span {
            color: #333;
            font-size: 18px;
            line-height: 1.6;
            font-weight: bold;
        }
    }
}

.tabs-policy {
    .ant-tabs-left-bar {
        height: 275px !important;
    }

    &.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
        margin-right: 10px !important;
    }
}

.hoveing-tabs {
    &.ant-tabs {
        .ant-tabs-bar {
            border: none;
        }

        .ant-tabs-nav-wrap {
            position: fixed !important;
            width: 100%;
            top: 183px;
            padding: 46px 0 10px 0;
            background-color: #fff;
        }
    }

    .ant-tabs-nav-scroll {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 880px;
            height: 1px;
            background-color: #e5e5e5;
            z-index: -1;
        }
    }
}

.info-article-name {
    font-size: 24px;
    text-align: left;
    color: #293335;
}

.info-article-type {
    margin-top: 20px;
    // padding: 0 15px 0 15px;
}

.info-type-date {
    // color: @text;

    &>span {
        margin-right: 100px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.info-type-operate {
    .icon {
        font-size: 14px;
        margin-left: 5px;
    }

    span {
        margin-left: 20px;
        cursor: pointer;
        vertical-align: top;

        &:hover {
            color: @primary-color;
        }

        &:active {
            color: @primary-color;
        }

        &:first-child {
            margin-left: 0;
        }
    }
}

.ant-card-body {
    .info-article-details {
        padding: 0;
        margin-top: 40px;

        a {
            word-break: break-all;
        }
    }
}

.info-article-details {
    font-size: 16px;
    line-height: 1.7;
    padding: 16px;
    color: @gray-two-color;

    * {
        max-width: 100% !important;
    }
}

.info-comment {
    margin-top: 30px;

    .paging {
        margin: 30px 0 40px;
    }
}

.info-second-title {
    font-size: 18px;
    color: @gray-one-color;
    position: relative;
    padding-left: 12px;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 4px;
        height: 18px;
        background-color: @primary-color;
        transform: translateY(-50%);
    }
}

.comment-write {
    margin-top: 10px;

    .ant-form-item-control {
        text-align: right;
    }
}

/*资讯详情end*/

/*圈子详情*/
.describe-three {
    height: 85px;

    .ant-typography {
        line-height: 28px;
    }
}

.paging-circle {
    margin: 35px 0;

    .ant-pagination {
        text-align: center;
    }
}

.circle-details-infor {
    .img {
        display: block;
        width: 100%;
    }

    .icon-box {
        margin-bottom: 5px;

        em {
            display: inline-block;
            margin: 0 10px;
            color: #ddd;
        }
    }

    .title {
        font-size: 18px;
        font-weight: bold;
        margin: 10px 0;
    }

    .row {
        margin-bottom: 5px;
    }

    .ant-btn {
        margin: 15px 0;
    }
}

.circle-member-card {
    .ant-card-head-title {
        font-size: 18px;
    }

    .ant-list-item {
        border-bottom: 1px dashed #e5e5e5 !important;

        &:last-child {
            border-bottom: none !important;
        }
    }

    .ant-list-vertical {
        .ant-list-item-meta {
            margin-bottom: 0;
        }
    }
}

.circle-post-content {
    .ant-list-item-action {
        padding-left: 48px;
    }

    .ant-list-item-meta-description {
        color: #666;
    }
}

.circle-post-col {
    margin-top: 10px;

    .ant-col-23 {
        padding-left: 14px;
    }
}

.circle-carousel {
    height: 220px;

    .slick-list {
        height: 100%;
    }

    .slick-slide img {
        height: 100%;
        object-fit: cover;
    }
}

.circle-modal {
    .ant-modal-close-x {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
}

.toolip-circle {
    &.ant-tooltip {
        width: auto;
        max-width: 600px;
    }
}

/*圈子详情end*/

/*政策*/
.contact-form {
    .ant-form-item {
        margin-bottom: 0;
    }

    textarea.ant-input {
        margin-top: 3px;
    }
}

.policy-tips {
    font-size: 14px;
    color: @text;
    line-height: 20px;
}

.policy-phone {
    text-align: center;
    color: @primary-color;
    font-size: 16px;
    margin-top: 12px;

    .icon {
        margin-right: 5px;
    }
}

.info-tag {
    span {
        margin: 0 10px 10px 0;
    }
}

.info-article-item {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.info-article-title {
    // padding: 16px;
}

/*政策end*/

/*会议室详情*/
.details-name {
    div.ant-typography {
        margin-bottom: 10px;
    }
}

.ant-list.details-intro {
    .ant-list-item {
        padding: 1px 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    // .ant-list-item:last-child {
    //     border-bottom: #e8e8e8 solid 1px;
    // }
}

.type-name {
    display: inline-block;
    color: @text;
    margin-right: 10px;
    //width: 80px;
}

.details-content-title {
    font-size: 18px;
    color: @text-color;
    margin-bottom: 10px;
}

.details-content-write {
    padding-left: 16px;
    font-size: 14px;
    color: @gray-two-color;

    p {
        margin-bottom: 0;
    }
}

.small-price {
    color: @error-color;
    font-size: 16px;
}

.my-order-litstyle {
    display: flex;
    justify-content: space-between;

    .my-order-status {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
}

.my-order-title {
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: #d30f1b;
    text-align: right;
}

.btn-webstyle {
    text-align: center;

    button {
        margin: 0 5px;
    }
}

//优惠券
.coupons-box {
    width: 75px;
    height: 75px;
    position: relative;
    overflow: hidden;
    color: @white-color;
    background-image: linear-gradient(138deg, rgb(247, 167, 44) 0%, rgb(248, 137, 57) 60%, rgb(248, 128, 0) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
}

.coupons-mylist {

    // height: 152px;
    .ant-card-body {
        .ant-list-item {
            padding: 16px 0;
        }
    }
}

.coupons {
    // cursor: pointer;
    border: solid 1px #ff8300 !important;
}

.coupons-get {
    border: solid 1px #ff8300 !important;
}

.coupons-downlist {

    &.ant-collapse-borderless,
    &.ant-collapse {
        background-color: initial;
    }

    .ant-collapse-item {
        border-bottom: none !important;
    }

    .ant-collapse-header {
        padding: 0 !important;
    }

    .ant-collapse-arrow {
        position: absolute !important;
        top: 11px !important;
        left: 135px !important;
        font-size: 14px !important;
        color: @primary-color !important;
    }

    .ant-collapse-content-box {
        padding: 0 !important;
    }
}

div {
    .btn-Jaune {
        background-color: rgb(255, 153, 0);
        float: right;
        border-radius: 17px;
        height: 26px;
        padding: 0 16px;
        line-height: 24px;
        border: #f37d00 solid 1px;
        cursor: pointer;
        margin-right: 0;
    }
}

.conpountitle-num {
    display: flex;
    justify-content: space-between;
}

.coupons-mylist {
    .ant-list-item {
        display: block !important;

        button {
            &:hover {
                background-color: unset;
                color: unset;
            }
        }
    }

    .mycoupon-btn-style {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .coupon-touse {
            background-color: #f89235;
            border-color: #f89235;
            margin-left: 5px;

            &:hover {
                background-color: #ed790f !important;
                border-color: #ed790f !important;
                margin-left: 5px;
            }
        }
    }

    .coupons-box-end,
    .coupon-touse-end {
        background: #eee !important;
        border-color: #eee !important;
    }

    .coupons-end {
        border: solid 1px #eee !important;
    }
}

.union-title {
    margin-bottom: 25px;

    .union-title-name {
        font-size: 26px;
        color: #000;
        position: relative;
        padding-left: 22px;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 6px;
            height: 24px;
            border-radius: 6px;
            background-color: #096dd9;
        }
    }
}

.matching-words-tag {
    .ant-tag {
        border: 1px solid #e6e6e6;
        height: 34px;
        line-height: 34px;
        padding: 0 20px;
        font-size: 14px;
        margin: 3px;
    }
}

.declare-form {
    width: 100%;
    padding: 50px 100px 30px;
    box-shadow: 0 6px 16px 0 rgba(14, 61, 124, 0.1);

    .title {
        font-weight: 650;
        font-style: normal;
        font-size: 28px;
        color: #2a569a;
        text-align: center;
        padding-bottom: 40px;
        border-bottom: #2a569a solid 1px;
    }

    .header {
        background-color: #fdd0001a;
        border: none;
    }

    .main {
        padding: 16px 0;

        .ant-input {
            border: none;
            background-color: #f9f9f9;
            height: 55px;
            outline: none;
            box-shadow: unset;
        }
    }
}

.declare-submit {
    text-align: center;
    padding: 50px 0;

    .icon {
        border-radius: 50%;
        height: 100px;
        width: 100px;
        background-color: #6eef05;
        font-size: 45px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: #fff;
        margin: 0 auto;
    }
}

.matching-choose-list {
    span {
        background-color: #f2f2f2;
        display: inline-block;
        padding: 0 10px;
        line-height: 26px;
        font-size: 16px;
        border-radius: 2px;
        margin: 6px 5px 6px 0;

        em {
            font-style: normal;
            color: #000;
        }
    }
}

/*会议室详情end*/

/*入驻申请成功*/
.success-content {
    margin: 50px 0;
    text-align: center;

    .success-icon {
        .icon {
            color: @primary-color;
            font-size: 60px;
        }
    }

    .success-name {
        font-size: 20px;
        margin-top: 10px;
    }

    .success-tips {
        margin-top: 10px;
    }

    .success-btn {
        margin-top: 20px;
    }
}

/*入驻申请成功end*/

/*园区印象*/
.login-form-button {
    margin-top: 5px;
}

.impression-info {
    line-height: 26px;

    padding: 0 16px;

    .type-name {
        width: auto;
    }

    .ant-col-12 {
        margin-bottom: 10px;
    }
}

.check-map {
    font-style: normal;
    color: @primary-color;
    margin-left: 10px;
    cursor: pointer;

    i {
        font-style: normal;
    }
}

.impression-map {
    max-height: 400px;

    .ant-list-item {
        padding: 0;
    }
}

/*园区印象end*/

/*广告位预订*/
.reserve-tag-content {
    margin-top: -5px;
    margin-bottom: 10px;

    .ant-tag {
        margin: 4px 8px 4px 0;
    }

    .ant-tag-checkable {
        background-color: @white-color;
        line-height: 30px;
    }

    .ant-tag-checkable-checked {
        background-color: @primary-color;
    }

    .ant-tag.active {
        background-color: @error-color;
    }
}

.state-tag-tips {
    span {
        position: relative;
        padding-left: 20px;
        margin-right: 20px;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 14px;
            height: 14px;
            border: #e8e8e8 solid 1px;
        }

        &:nth-child(1) {
            &:before {
                background-color: @white-color;
            }
        }

        &:nth-child(2) {
            &:before {
                background-color: @error-color;
            }
        }

        &:nth-child(3) {
            &:before {
                background-color: @primary-color;
            }
        }
    }
}

.choice-tag-list {
    background-color: #f5f5f5;
    padding: 12px;
    margin-top: 5px;
}

/*广告位预订end*/

/*会议室时间段start*/

.room-details {
    .bd {
        position: relative;

        .tit {
            display: flex;

            span {
                display: inline-block;
                font-size: 14px;
                color: #ff9900;
                font-weight: bold;

                &:first-child {
                    flex: 1;
                }

                &:nth-of-type(2) {
                    width: 80px;
                    text-align: right;
                }

                &:nth-of-type(3) {
                    width: 80px;
                    text-align: right;
                }
            }
        }

        .row {
            display: flex;
            padding: 5px 0;

            span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: inline-block;
                font-size: 14px;

                &:first-child {
                    flex: 1;
                }

                &:nth-of-type(2) {
                    width: 80px;
                    text-align: right;
                }

                &:nth-of-type(3) {
                    width: 80px;
                    text-align: right;
                }

                &.red {
                    color: #fd3301;
                    font-weight: bold;
                }
            }
        }

        .bt {
            position: relative;
            display: flex;
            padding: 10px 0 5px 0;

            &:before {
                content: " ";
                position: absolute;
                left: 0;
                right: 0;
                height: 1px;
                color: #e5e5e5;
                top: 0;
                border-top: 1px solid #f0f0f0;
                -webkit-transform-origin: 0 0;
                transform-origin: 0 0;
                -webkit-transform: scaleY(0.5);
                transform: scaleY(0.5);
            }

            span {
                display: inline-block;
                font-size: 14px;

                &:first-child {
                    flex: 1;
                }

                &:nth-of-type(2) {
                    width: 80px;
                    text-align: right;
                }

                &:nth-of-type(3) {
                    width: 80px;
                    text-align: right;
                    font-weight: bold;
                    color: #333;
                }
            }
        }
    }
}

.select-time {
    position: relative;
    background-color: @white-color;

    .tit {
        display: flex;
        justify-content: center;
        padding: 10px 15px 5px 15px;

        span {
            display: inline-block;
            width: 60px;
            height: 24px;
            text-align: center;
            line-height: 24px;
            font-size: 12px;
            color: #666;
            border-radius: 3px;
            transform: scale(0.8);
            margin-left: -5px;

            &:after {
                border-radius: 3px;
            }

            &.off {
                background-color: #f4f4f4;
                color: #999999;
            }

            &.actived {
                background-color: fade(@primary-color, 36%);
                color: @white-color;
            }

            &.on {
                background-color: @primary-color;
                color: @white-color;
            }
        }
    }

    .getout-time {
        padding: 5px 15px 10px 15px;
        font-size: 12px;
        color: @highlight-color;

        i {
            position: relative;
            vertical-align: middle;
            font-size: 14px;
            top: -1px;
        }
    }

    .bd {
        position: relative;
        max-width: 100%;
        overflow-y: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        margin: 0 15px;
        padding: 5px 0;
    }

    .fd {
        display: flex;
        padding: 15px;
        justify-content: space-around;
        align-items: center;

        .timetit {
            font-size: 14px;
            flex: 1;
            text-align: center;
        }

        .prev,
        .next {
            position: relative;
            display: flex;
            width: 22px;
            height: 22px;
            border-radius: 22px;
            justify-content: center;
            align-items: center;
            color: #fff;
            background-color: @highlight-color;
        }

        .prev {
            &::before {
                content: "";
                display: inline-block;
                width: 12px;
                height: 2px;
                background-color: #fff;
                border-radius: 1px;
            }
        }

        .next {
            &::before {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 12px;
                height: 2px;
                background-color: @white-color;
                border-radius: 1px;
            }

            &::after {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 2px;
                height: 12px;
                background-color: @white-color;
                border-radius: 1px;
            }
        }
    }
}

.time-list {
    position: relative;
    white-space: nowrap;

    &:before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        width: 1px;
        bottom: 0;
        border-left: 1px solid #f0f0f0;
        color: #e5e5e5;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: scaleX(0.5);
        transform: scaleX(0.5);
    }

    li {
        position: relative;
        display: inline-block;
        width: 35px;
        height: 100px;

        &:before {
            content: " ";
            position: absolute;
            left: 0;
            right: 0;
            height: 1px;
            color: #e5e5e5;
            bottom: 0;
            border-bottom: 1px solid #f0f0f0;
            -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
            -webkit-transform: scaleY(0.5);
            transform: scaleY(0.5);
        }

        &:after {
            content: " ";
            position: absolute;
            right: 0;
            top: 0;
            width: 1px;
            bottom: 0;
            border-right: 1px solid #f0f0f0;
            color: #e5e5e5;
            -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
            -webkit-transform: scaleX(0.5);
            transform: scaleX(0.5);
        }

        &:nth-of-type(2n + 1) {
            &:after {
                top: 35px;
            }
        }

        &:last-child {
            &:after {
                content: " ";
                position: absolute;
                right: 0;
                top: 0;
                width: 1px;
                bottom: 0;
                border-right: 1px solid #f0f0f0;
                color: #e5e5e5;
                -webkit-transform-origin: 100% 0;
                transform-origin: 100% 0;
                -webkit-transform: scaleX(0.5);
                transform: scaleX(0.5);
            }
        }

        .time {
            display: block;
            font-size: 10px;
            color: #000;
            text-align: center;
            margin-top: 5px;
        }

        .main {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 35px;
        }

        &.off {
            .main {
                background-color: #f4f4f4;
            }
        }

        &.actived {
            .main {
                background-color: fade(@primary-color, 36%);
            }
        }

        &.on {
            .main {
                background-color: @primary-color;
            }
        }

        &:nth-of-type(2n) {
            .time {
                color: #fff;
            }
        }
    }
}

.img-tabs {
    a {
        height: 131px;
        display: block;
        width: 100%;
        font-size: 22px;
        color: #333;
        background: no-repeat 50%;
        background-size: cover;
        position: relative;
        padding: 30px 0 0 24px;
        border-radius: 4px;

        .tabs-eng {
            font-size: 16px;
            color: #acb0b1;
        }

        &:hover,
        &.active {
            box-shadow: 0 6px 16px 0 rgba(4, 20, 42, 0.1);
            color: #333333;
        }
    }

    .ant-col-8 {
        &:first-child {
            a {
                background-image: url("../../assets/images/policy/policy_left.png");

                &.active {
                    &:after {
                        content: "";
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        border-radius: 4px;
                        border: 2px solid #48c9d3;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }

        &:nth-child(2) {
            a {
                background-image: url("../../assets/images/policy/policy_center.png");

                &.active {
                    &:after {
                        content: "";
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        border-radius: 4px;
                        border: 2px solid #3e96d4;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }

        &:nth-child(3) {
            a {
                background-image: url("../../assets/images/policy/policy_right.png");

                &.active {
                    &:after {
                        content: "";
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        border-radius: 4px;
                        border: 2px solid #32b16c;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
    }

    .ant-col-12 {
        &:first-child {
            a {
                background-image: url("../../assets/images/policy/policy_center.png");

                &.active {
                    border: 2px solid #3e96d4;
                }

                &.active,
                &:hover {
                    color: #3e96d4;
                }
            }
        }

        &:nth-child(2) {
            a {
                background-image: url("../../assets/images/policy/policy_right.png");

                &.active {
                    border: 2px solid #32b16c;
                }

                &.active,
                &:hover {
                    color: #32b16c;
                }
            }
        }
    }
}

/*会议室end*/

.my-upload-box .my-upload-button {
    width: 150px;
    height: 150px;
    overflow: hidden;
    display: inline-block;
    position: relative;
    margin: 5px 0;
    text-align: center;
    border: 1px solid #bdbdbd;
    border-radius: 50%;

    .ant-upload.ant-upload-select-picture-card,
    .ant-upload-list-picture-card-container {
        width: 150px;
        height: 150px;
    }

    .ant-upload.ant-upload-select-picture-card {
        background: rgba(255, 255, 255, 0.3);
    }
}

.my-upload-box .my-upload-button .my-upload-img-opacity {
    opacity: 0;
    z-index: 10;
}

.my-upload-box .my-upload-button input {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.my-upload-box .my-upload-button .images-auto {
    border-radius: 50%;
}

.bdshare_popup_bottom {
    display: none;
}

.share-badge {
    .bdsharebuttonbox {
        .bds_more {
            float: none;
            padding: 0;
            background: none;
            color: rgba(0, 0, 0, 0.45);
            font-size: 14px;
            margin-bottom: 0;
        }
    }
}

.park-details {
    * {
        max-width: 100% !important;
    }
}

.attach .upload {
    // padding: 10px 8px 10px 15px;
    padding-left: 15px;
    height: 44px;

    >div {
        width: 100%;
    }

    >div {
        width: 100%;
    }

    .webuploader-pick {
        span {
            display: flex;
            align-items: center;
        }

        i {
            margin-right: 5px;
            margin-top: 0;
        }
    }
}

.upload-box,
.webuploader-container {
    display: flex;
    align-items: center;

    i {
        margin-right: 3px;
        line-height: 1;
        margin-top: 2px;
    }
}

.file-input,
.webuploader-element-invisible {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.type-box {
    position: relative;

    span {
        position: absolute;
        font-size: 12px;
        bottom: 0;
        left: 5px;
        line-height: 1.2;
        -webkit-transform: scale(0.5);
        transform: scale(0.6);
        background-color: @white-color;
        max-width: 36px;
        overflow: hidden;
        text-overflow: ellipsis;

        i {
            display: block;
            position: relative;
            top: 5px;
        }
    }
}

.no-notice {
    background-color: transparent;
    color: #333333;
}

.readonly {
    .ant-upload.ant-upload-select-picture-card {
        display: none;
    }

    .ant-upload.ant-upload-disabled {
        display: none;
    }
}

/*我的发票*/
.button-title {
    line-height: 32px;
}

/*我的发票end*/
.use-coupon-select {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;

    .ant-list-item-meta-content {
        width: 100%;
    }
}

.choseNo {
    .coupons {
        border: solid 1px #f5f5f5 !important;
    }

    .anticon-check {
        display: none;
    }
}

.choseActive {
    .coupons {
        border: solid 1px #ff9a1b !important;
    }

    .anticon-check {
        display: inline-block;
    }
}

.invioce-vire-shoice {
    .ant-form-item {
        margin-bottom: 2px !important;
    }

    .ant-select-lg .ant-select-selection--single {
        height: 31px !important;
    }

    .ant-select-lg .ant-select-selection__rendered {
        line-height: 30px !important;
        font-size: 13px !important;
    }

    .ant-form-item-children {
        .ant-input[disabled] {
            height: 30px;
            padding: 0 11px;
            font-size: 14px;
            line-height: 30px;
        }
    }
}

.position-timepicker {
    .ant-calendar-picker {
        margin-right: 5px;
    }
}

.ant-card {
    &.index-activity-card {
        transition: all 0.4s;
        cursor: pointer;

        h4 {
            height: 56px;
        }

        &:hover {
            h4 {
                color: @primary-color;
            }
        }
    }
}

/*我的-最新消息*/
.info-min {
    min-height: 454px;
}

.my-article {
    position: relative;
    overflow: hidden;
    padding: 15px;
    border: 1px solid #f0f0f0;

    .ant-page-header {
        padding: 16px;
    }

    a {
        color: rgba(0, 0, 0, 0.85);

        &:hover {
            color: #096dd9;
        }
    }
    .size-18{
        font-size: 17px !important;
    }
}

/*我的-最新消息end*/

/* 级联选择 */

.tree-list {
    margin-bottom: -1px;
}

.tree-list .tree-node .tree-node {
    position: relative;
    // border-bottom: 1px solid #eee;
}

div.sum-box {
    float: left;
    display: block;
    position: relative;
    width: 20%;
    overflow: hidden;
    padding-right: 5%;
    text-align: right;
}

.tree-box {
    display: block;
    position: relative;
    overflow: hidden;
}

.tree-node-title.active {
    color: #488be9;
}

.tree-node-title .tree-node-arrow {
    display: inline-block;
    width: 1.6rem;
    text-align: center;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}

.tree-node-title .tree-node-link {
    float: right;
    color: rgba(136, 136, 136, 0.45);
    font-size: 1.6rem;
}

.tree-node.active>.tree-box>.am-flexbox.am-flexbox-align-center>.tree-node-title>.am-flexbox.am-flexbox-align-center>.tree-node-arrow {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.tree-node.active>.tree-box {
    // border-bottom: 1px solid #eee;
}

.tree-node>.tree-node {
    display: none;
}

.tree-node.active>.tree-node {
    display: block;
}

.dropdown-content .tree-list {
    margin-bottom: 0;
    border-top: 1px solid #ddd;
}

div.tree-node-title {
    display: block;
    padding: 0.6rem 0.6rem 0.6rem 0;
    text-align: left;
    color: #888;

    .number {
        position: absolute;
        top: 50%;
        right: 5%;
        margin-top: -0.8rem;
        min-width: 10px;
        padding: 0 12px;
        color: #666;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        font-size: 0.9rem;
        margin-right: 0;
    }
}

a.tree-node-title {
    display: block;
    padding: 0.6rem 0.6rem 0.6rem 1.9rem;
    text-align: left;
    color: #888;

    .number {
        position: absolute;
        top: 50%;
        right: 5%;
        margin-top: -0.8rem;
        min-width: 10px;
        padding: 0 12px;
        color: #666;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        font-size: 0.9rem;
        margin-right: 0;
    }
}

.number {
    display: inline-block;
    min-width: 10px;
    padding: 0 12px;
    width: 30px;
    color: #666;
    white-space: nowrap;
    vertical-align: baseline;
    margin-top: 0.8rem;
    margin-right: 0;
    font-size: 0.9rem;
    text-align: center;
}

.tree-node.active>.tree-node {
    padding-left: 1rem;
}

.tree-node.active>.tree-node.active>.tree-node:last-child {
    border-bottom: 0;
}

.tree-model .showed {
    height: calc(~"100vh - 203px") !important;
}

/* END 级联选择 */

/* 评价tag */
.apply-modal-tag {
    margin: 8px 0 10px 0;
    max-height: 138px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .tag-item {
        min-width: 50%;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        padding-bottom: 8px;

        &:nth-child(odd) {
            padding-right: 4px;
        }

        &:nth-child(even) {
            padding-left: 4px;
        }

        &.active {
            span {
                background-color: @primary-color;
                color: @white-color;
            }
        }
    }

    span {
        background-color: #f2f2f2;
        color: @text;
        padding: 0 6px;
        border-radius: 5px;
        font-size: 14px;
        height: 38px;
        line-height: 1.2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        box-sizing: border-box;
    }
}

/*END 评价tag */

/* 显示隐藏搜索框 */
.select-hide {
    text-align: center;
    width: 77px;
    height: 40px;
    margin: 0 auto;

    .select-hide-box {
        width: 77px;
        height: 40px;
        line-height: 40px;
        background: #ffffff;
        border: 1px solid #f0f0f0;
        border-radius: 0 0 4px 4px;
        cursor: pointer;
        border-top: 0;
        margin-top: -1px;
        position: relative;
        z-index: 1;

        span {
            color: rgba(0, 0, 0, 0.45);

            i {
                transform: scale(0.7);
                color: rgba(0, 0, 0, 0.45);
            }
        }
    }
}

/* END 显示隐藏搜索框 */

/* 排序用a标签 */
.sortBox {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
    line-height: unset;

    .active {
        color: #fff !important;
        border: 1px solid @primary-color !important;
        background-color: @primary-color !important;
    }

    .first {
        border: 1px solid #d9d9d9;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .last {
        border: 1px solid #d9d9d9;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .other {
        border: 1px solid #d9d9d9;
        border-left: 0;
    }

    .sortButton {
        position: relative;
        display: inline-block;
        height: 40px;
        margin: 0;
        padding: 0 15px;
        color: rgba(0, 0, 0, 0.45);
        line-height: 40px;
        background: #fff;
        border-top-width: 1.02px;
        cursor: pointer;
        transition: color 0.3s, background 0.3s, border-color 0.3s;
    }
}

/* END 排序用a标签 */

/* 分类用a标签 */
.classify {
    color: rgba(0, 0, 0, 0.85);
    height: 30px;
    line-height: 28px;
    margin: 10px 20px 10px 0;
    cursor: pointer;
    padding: 0 16px;
    font-size: 16px;
    box-sizing: border-box;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
    white-space: nowrap;
    border: 1px solid transparent;
    border-radius: 4px;
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    background-color: transparent;
}

.classify-checked {
    color: #fff;
    background-color: @primary-color !important;
    height: 30px;
    line-height: 28px;
    font-size: 16px;
    border-radius: 2px;
    padding: 0 16px;

    &:hover {
        background-color: @primaryHover-color !important;
        color: #fff;
    }
}

/* END 分类用a标签 */

/* 园区切换a标签 */
.park-select-box {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    display: inline-block;
    line-height: unset;
    width: 100%;

    .park-radio-box {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.6;
        list-style: none;
        font-feature-settings: "tnum";
        position: relative;
        display: inline-block;
        margin-right: 8px;
        white-space: nowrap;
        cursor: pointer;
        width: calc(~"50% - 8px");
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;

        :hover {
            .park-radio-inner {
                border-color: #2289cb !important;
            }
        }

        .park-radio {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            font-variant: tabular-nums;
            list-style: none;
            font-feature-settings: "tnum";
            position: relative;
            display: inline-block;
            line-height: 1;
            white-space: nowrap;
            vertical-align: sub;
            outline: none;
            cursor: pointer;

            .park-radio-input {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
                cursor: pointer;
                opacity: 0;
            }
        }
    }
}

.park-radio-checked {
    .park-radio-inner {
        border-color: #2289cb;
    }

    .park-radio-inner:after {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
        transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }
}

.park-radio-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #2289cb;
    border-radius: 50%;
    visibility: hidden;
    -webkit-animation: antRadioEffect 0.36s ease-in-out;
    animation: antRadioEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    content: "";
}

.park-radio-text {
    padding-right: 8px;
    padding-left: 8px;
}

.park-radio-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-color: #d9d9d9;
    border-style: solid;
    border-width: 1px;
    border-radius: 100px;
    transition: all 0.3s;
}

.park-radio-inner::after {
    position: absolute;
    top: 3px;
    left: 3px;
    display: table;
    width: 8px;
    height: 8px;
    background-color: #2289cb;
    border-top: 0;
    border-left: 0;
    border-radius: 8px;
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: " ";
}

/* END 园区切换a标签 */

.bd_weixin_popup {
    height: 300px !important;
}

.center-steps.ant-steps {
    padding: 50px 70px 20px;

    &.ant-steps-label-vertical {
        .ant-steps-item-content {
            width: 160px;
            position: relative;
        }

        .ant-steps-item-icon {
            margin-left: 60px;
            border-width: 2px;
            line-height: 42px;
            height: 42px;
            width: 42px;

            .ant-steps-icon {
                font-size: 20px;
                font-weight: bold;
                font-family: Arial;

                .icon {
                    font-size: 42px;
                    line-height: 1;
                    font-weight: bold;
                }
            }
        }

        .ant-steps-item-tail {
            margin-left: 80px;
            top: 17px;
            padding: 3.5px 32px;
        }
    }

    .ant-btn {
        height: 24px;
        line-height: 22px;
        border-radius: 12px;
        padding: 0 27px;
    }

    .ant-steps-item-title {
        font-size: 18px;
        color: #000 !important;
        position: absolute;
        width: 100%;
        left: 0;
        top: -95px;
    }

    .ant-steps-item-tail::after {
        height: 2px;
    }

    .ant-steps-item-description {
        margin-top: 12px;
    }
}

.report-form {
    margin: 20px 0;
}

/*表格*/
.report-form {
    margin: 30px 0;

    .ant-checkbox-wrapper {
        font-size: 16px;
    }

    .ant-checkbox-wrapper+.ant-checkbox-wrapper {
        margin: 0 15px 0 0;
    }

    .ant-calendar-picker {
        .ant-calendar-picker-input {
            border: 1px solid #d9d9d9;
            height: 40px;
            line-height: 40px;
            border-radius: 4px;
            padding: 0 10px;
            width: 100%;
            box-sizing: border-box;
            outline: none;

            &.inline {
                display: inline-block;
                width: auto;
                min-width: 200px;
                margin-right: 10px;
            }

            &.small {
                display: inline-block;
                width: 60px;
                margin: 0 10px;
                border: none;
                border-bottom: #d9d9d9 solid 1px;
                border-radius: 0;
            }
        }
    }

    input[type="text"],
    input[type="number"] {
        border: 1px solid #d9d9d9;
        height: 40px;
        line-height: 40px;
        border-radius: 4px;
        padding: 0 10px;
        width: 100%;
        box-sizing: border-box;
        outline: none;

        &.inline {
            display: inline-block;
            width: auto;
            min-width: 200px;
            margin-right: 10px;
        }

        &.small {
            display: inline-block;
            width: 60px;
            margin: 0 10px;
            border: none;
            border-bottom: #d9d9d9 solid 1px;
            border-radius: 0;
        }
    }

    .chapter {
        height: 150px;
        margin-top: 20px;
    }

    .table-division {
        color: @primary-color;
    }
}

.report-form-wd {
    padding: 50px;
    box-shadow: 0 0 15px #eee;
}

.report-form-title {
    text-align: center;
    font-size: 24px;
    color: @primary-color;
    line-height: 1.4;

    p {
        margin: 0;
    }
}

.report-form-type {
    font-size: 16px;
    margin: 20px 0 20px 0;
}

.table-list {
    width: 100%;
    border: #e6e6e6 solid 1px;
    font-size: 16px;

    td {
        padding: 10px 15px;
        min-height: 20px;
        border: #e6e6e6 solid 1px;

        &.bg {
            color: #000;
            width: 200px;
        }
    }

    .table-title {
        font-size: 18px;
        color: #000;
        background-color: #f5f5f5;
    }
}

/*表格end*/

/*自定义上传表格*/
.report-form {
    .personlist {
        font-size: 16px;

        .bt_1307_regTitle {
            margin: 25px 0 15px;
        }

        .title {
            font-size: 20px;
            color: @gray-one-color;
            position: relative;
            padding-left: 20px;
            display: inline-block;
            padding-bottom: 0;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 6px;
                height: 18px;
                background-color: @primary-color;
                border-radius: 4px;
            }
        }

        td {
            padding: 10px;
            border: #e6e6e6 solid 1px;
        }
    }

    .ant-row-flex-middle {
        button {
            margin-left: 10px;
        }
    }
}

.report-form-detail {
    padding: 0 40px;

    .personlist {
        font-size: 14px;

        .title {
            font-size: 17px;
            padding-left: 0;

            &::before {
                display: none;
            }
        }
    }
}

.report-form-detail {
    padding: 0 40px;

    .personlist {
        font-size: 14px;

        .title {
            font-size: 17px;
            padding-left: 0;

            &::before {
                display: none;
            }
        }
    }
}

.personlist {
    .ant-col {
        >span {
            &:first-child {
                color: rgba(0, 0, 0, 0.45);
            }

            color: rgba(0, 0, 0, 0.85);
        }
    }
}

/*自定义上传表格end*/

.my-page {
    .ant-page-header-heading-title {
        font-weight: unset;
    }

    .ant-page-header-ghost {
        background-color: #fff;
        border: #e5e5e5 solid 1px;
        border-radius: 2px;
        padding: 16px;

        .ant-page-header-heading-extra {
            margin-top: 6px;
        }

        .ant-page-header-heading-sub-title {
            font-weight: unset;

            a {
                span {
                    color: rgba(0, 0, 0, 0.65);

                    &:hover {
                        color: @primary-color;
                    }
                }
            }
        }
    }
}

.my-home-title {
    font-size: 18px;
}

.spacemap {
    z-index: 10 !important;
    margin: 0 10px;
}

.flowstate_select {
    .ant-select-selection {
        .ant-select-selection__rendered {

            .ant-select-selection-selected-value,
            .ant-select-selection__placeholder {
                font-size: 16px;
            }
        }
    }
}

.extra-button {
    width: 80px;
}

/* 圈子 */
.circle-comment {
    &.pinglun-box {
        // .ant-comment-actions > li:last-child {
        //     position: absolute;
        //     right: 0;
        //     top: 0;
        // }

        .ant-comment-content-detail {
            padding-right: 0;
        }
    }

    .replyon-comment {
        .ant-comment-content-detail {
            padding-right: 20px !important;
        }
    }

    .pinglun-box {
        padding-left: 15px;
        padding-right: 15px;

        .ant-comment-nested {
            border-radius: 5px;
            background-color: #f9f9f9;
            // margin-bottom: 15px;
        }
    }

    .reply-box {
        margin-top: 10px;
        border-radius: 6px;
        padding: 0 16px;
        background-color: #f9f9f9;

        .ant-comment-content-author {
            display: none;
        }

        .ant-comment-avatar {
            display: none;
        }
    }
}

.dynamic-box {
    .swiper-container {
        .swiper-slide {
            // height: auto !important;
        }
    }
}

.row-my-infos {
    padding: 10px 0;
    background-color: #fff;
}

.my-form {
    .ant-form-item {
        margin-bottom: 0;

        .ant-form-item-label {
            text-align: left;
        }

        .ant-col {
            width: 100%;
        }
    }

    .ant-select-selection--single {
        height: 45px;
    }

    .ant-select-selection__rendered {
        line-height: 43px;
    }

    .ant-input {
        height: 45px;
    }

    textarea.ant-input {
        height: auto;
    }

    .ant-tag {
        background: #f2f2f2;
        color: #555555;
        border: none;
        border-radius: 0;
        padding: 3px 20px;
        margin-right: 15px;
        font-size: 14px;
        letter-spacing: 2px;
        cursor: pointer;

        &.active {
            color: #05b8ce !important;
            background-color: #eaf8fa !important;
        }
    }
}

.form-space {
    position: relative;
    overflow: hidden;
    padding: 30px 0;

    &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        top: 50%;
        left: 0;
        border-top: 1px dashed #ddd;
    }
}

.extra-font {
    font-size: 16px;
    font-weight: normal !important;
    color: #bdbdbd !important;
}

.circle-dynamic-card .ant-card-body {
    padding: 16px;

    .ant-list-item {
        padding: 0;
    }
}

/* 圈子 END */

/* 状态机 */
.drawer-detail {
    max-height: 500px;
    overflow: auto;
    overflow-x: hidden;
}

.flowstate_table {
    display: block !important;
}

.approval-modal-form {
    width: 700px !important;
}

.service-detail-card-title {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.6;
    list-style: none;
    font-feature-settings: "tnum";
}

/* 状态机 END */

.my-upload-box {
    .my-upload-button {
        .ant-avatar {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
        }

        //.ant-upload-list-item-actions {
        //    display: none;
        //}
        //
        //.ant-upload-list-item-info {
        //    display: none;
        //}

        .ant-upload.ant-upload-select-picture-card {
            position: absolute;
            top: 0;
            left: 0;
            border: none;
        }

        .ant-upload-list-picture-card .ant-upload-list-item {
            padding: 0;
        }

        .ant-upload-list-picture-card .ant-upload-list-item-info::before {
            left: 0;
            top: 0;
            border-radius: 50%;
        }

        .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
        .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
            object-fit: cover;
        }

        .ant-upload-animate-inline-appear-active {
            +.ant-upload-select-picture-card {
                display: none;
            }
        }
    }

    .ant-upload-list ant-upload-list-picture-card,
    ant-upload-list-picture-card-container {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.pre-wrap {
    white-space: pre-wrap;
}

.ck-content {
    // min-height: 200px;
}

.nt-advanced-search-form {
    .ant-tag {
        cursor: pointer;

        &.active {
            color: #05b8ce !important;
            background-color: #eaf8fa !important;
        }
    }
}

.model-list-box {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.month-salary-input {
    .ant-form-item-control-input-content {
        display: flex;
        align-items: center;

        .ant-input-group {
            width: auto;
        }
    }
}

/* 审批 */
.second-menu {
    &.ant-menu-inline {
        border: 1px solid #e8e8e8;

        .ant-menu-item {
            font-size: 16px;
            margin: 0 !important;
            height: 56px;
            line-height: 56px;
            border-bottom: 1px solid #f9f9f9;

            &.ant-menu-item-selected {
                background-color: #096dd9;
                color: #fff;
            }

            .icon {
                display: inline-block;
                -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
            }
        }
    }
}

.examine-card {
    .ant-card-body {
        padding: 0 20px;

        .ant-list-lg .ant-list-item {
            padding: 20px 0;

            &:hover {
                background-color: #f9fbff;
            }
        }
    }
}

/* 审批--end */
.ant-carousel {
    .enterprise-box {
        padding-bottom: 20px;

        .item {
            // height: 280px;
        }

        .slick-dots {
            justify-content: flex-end;
            margin: 0;
            bottom: 0;

            li {
                button {
                    background-color: #ddd;
                }

                &.slick-active {
                    button {
                        background-color: @primary-color;
                    }
                }
            }
        }
    }
}

/**
 * 富文本最小高度
 */
.ck.ck-editor__main>.ck-editor__editable {
    min-height: 100px;
}

// 按钮节流
.throttle {
    animation: throttlea 2s step-end forwards;
}

.throttle:active {
    animation: none;
}

@keyframes throttlea {
    from {
        pointer-events: none;
    }

    to {
        pointer-events: all;
    }
}
