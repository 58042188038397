@charset "UTF-8";
@import "themes/default";

@font-face {
    font-family: 'siyuan';
    src: url(../fonts/SourceHanSansCN.ttf);
}

@font-face {
    font-family: 'siyuan-regular';
    src: url(../fonts/SourceHanSansCN-Regular.ttf);
}

.siyuan {
    font-family: siyuan;
}

.regular {
    font-family: siyuan-regular;
}

.logo-name {
    font-size: 32px;
    letter-spacing: 4px;
    font-family: siyuan;
    cursor: pointer;
}

.head-a {
    a {
        color: @white-color;
        font-size: 16px;
    }
}

.head-menu {
    position: relative;
    overflow: hidden;
    z-index: 100;

    +.container-scrollable {
        margin-top: -86px;
    }
}

.ld-header {
    height: 86px;

    .ant-row {
        height: 86px;
        color: @white-color;
        border-bottom: rgba(255, 255, 255, 0.4) solid 1px;
    }
}

.consult-btn {
    height: 78px;
    display: flex;
    border-radius: 4px;
    background-color: @white-color;
    padding: 0 30px;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    color: #0c313c;
    box-shadow: 4.944px 15.217px 50px 0px rgba(7, 16, 48, 0.15);
    margin-top: 90px;
    margin-right: 58px;
    cursor: pointer;

    span {
        display: inline-block;
        transition: all 0.35s;
    }

    &:hover {
        span {
            transform: translateX(5px);
        }
    }
}

.custom-color1 {
    color: #0f265d !important;
}

.trends-swiper {
    .trends-swiper-title {
        transition: all 0.35s;
    }

    .swiper-slide {
        cursor: pointer;

        &:hover {
            .trends-swiper-title {
                color: @primary-color;
            }

            .trends-img {
                div.images-auto {
                    transform: scale(1.1);
                }
            }
        }
    }
}

.trends-img {
    height: 312px;
    overflow: hidden;

    div.images-auto {
        transition: all 0.35s;
    }
}

.find-content {
    margin-top: 70px;
    background: url("../images/ld/bg.jpg") no-repeat center top;
    padding: 90px 0 70px;
}

.find-grid {
    background-color: #fff;
    box-shadow: 4.944px 15.217px 50px 0px rgba(7, 16, 48, 0.15);
    border-radius: 8px;

    .ant-col {
        border-top: #ebebeb solid 1px;
        border-right: #ebebeb solid 1px;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            border-top: none;
        }
    }
}

.find-grid-item {
    text-align: center;
    height: 176px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.35s;

    .find-grid-icon {
        height: 60px;
        transition: all 0.35s;

        img {
            max-height: 100%;
        }
    }

    .find-grid-text {
        font-size: 18px;
        color: #0f265d;
    }

    &:hover {
        background-color: #f9f9f9;

        .find-grid-icon {
            transform: scale(1.1);
        }
    }
}

.recommended-item {
    box-shadow: 0 4px 8px 0 rgba(15, 38, 93, 0.18);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.35s;

    &:hover {
        transform: translateY(-5px);
    }
}

.page-components-rows-content {
    max-height: initial !important;

    .page-components-rows-select-data {
        max-height: initial !important;
    }
}

.fav-new {
    .ant-tabs-tab {
        margin: 0px 20px 0px 0px !important;
    }
}