@import "themes/default";

.footer {
  background-color: #eff1f9;
  color: #3b4560;
  padding: 24px 0 15px;
  border-top: #e8e8e8 solid 1px;
  font-size: 16px;

  .ant-typography {
    color: #0f265d;
  }

  .footer-desc {
    margin-top: 10px;
    font-size: 14px;
  }

  .footer-content {
    margin-right: 30px;

    ul,
    li {
      margin: 0;
      padding: 0 0 5px;
      list-style: none;
    }

    a {
      color: @foot-link-color;

      &:hover {
        color: @foot-link-color-hover
      }
    }
  }

  .ant-col:last-child {
    .footer-content {
      margin-right: 0;
    }
  }

  .footer-desc {
    p {
      margin-bottom: 0;

      i {
        margin-right: 3px;

        &.icon-phone {
          margin-right: 6px;
        }
      }
    }

    padding-bottom: 15px;
  }
}

.copyright {
  border-top: #e8e8e8 solid 1px;
  margin-top: 15px;
  padding-top: 15px;
}

.login-box {
  min-height: calc(100vh - 257px) !important;
}